import React from "react";
import { Select, MenuItem } from "@mui/material";

const LanguageSwitcher = ({
  languages,
  selectedLanguage,
  onLanguageChange,
}) => {
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    onLanguageChange(newLanguage);
  };

  return (
    <Select
      value={selectedLanguage}
      onChange={handleLanguageChange}
      variant="outlined"
      fullWidth
    >
      {languages.map((language) => (
        <MenuItem key={language.code} value={language.code}>
          {language.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSwitcher;
