import {
  Paper,
  Typography,
  TextField,
  Grid,
  Box,
  Button,
  Rating,
  List,
  ListItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../Styles/general.css";

const EditFeedbackForm = ({
  handleInputChange,
  handleSave,
  handleSubmit,
  feedback,
}) => {
  const { t } = useTranslation();
  return (
    <Paper elevation={3} sx={{ padding: 2, maxWidth: 600, margin: "auto" }}>
      <Typography variant="h5" gutterBottom>
        {t("feedbackEdit.feedbackTitle")}
      </Typography>
      <Typography variant="h6">{t("feedbackEdit.infoRating")}</Typography>
      <List>
        <ListItem>{t("feedbackEdit.infoRating1")}</ListItem>
        <ListItem>{t("feedbackEdit.infoRating2")}</ListItem>
        <ListItem>{t("feedbackEdit.infoRating3")}</ListItem>
      </List>
      <Typography variant="h6">{t("feedbackEdit.infoCommentary")}</Typography>
      <Typography variant="body1" gutterBottom>
        {t("feedbackEdit.infoCommentaryDesc")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t("feedbackEdit.titleAgreements")}
            </Typography>
            <Rating
              value={Number(feedback.agreementsAndDeadlinesRating)}
              max={3}
              defaultValue={1}
              onChange={(_, newValue) =>
                handleInputChange({
                  target: {
                    id: "agreementsAndDeadlinesRating",
                    value: newValue,
                  },
                })
              }
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label={t("commentary")}
              helperText={t("feedbackEdit.helptextAgreements")}
              value={feedback.agreementsAndDeadlinesComments}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    id: "agreementsAndDeadlinesComments",
                    value: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t("feedbackEdit.titleInitiative")}
            </Typography>
            <Rating
              value={Number(feedback.initiativeRating)}
              max={3}
              defaultValue={1}
              onChange={(_, newValue) =>
                handleInputChange({
                  target: {
                    id: "initiativeRating",
                    value: newValue,
                  },
                })
              }
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label={t("commentary")}
              helperText={t("feedbackEdit.helptextInitiative")}
              value={feedback.initiativeComments}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    id: "initiativeComments",
                    value: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t("feedbackEdit.titleAnalytical")}
            </Typography>
            <Rating
              value={Number(feedback.analyticalSkillsRating)}
              max={3}
              defaultValue={1}
              onChange={(_, newValue) =>
                handleInputChange({
                  target: {
                    id: "analyticalSkillsRating",
                    value: newValue,
                  },
                })
              }
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label={t("commentary")}
              helperText={t("feedbackEdit.helptextAnalytical")}
              value={feedback.analyticalSkillsComments}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    id: "analyticalSkillsComments",
                    value: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t("feedbackEdit.titleCommunication")}
            </Typography>
            <Rating
              value={Number(feedback.communicationSkillsRating)}
              max={3}
              defaultValue={1}
              onChange={(_, newValue) =>
                handleInputChange({
                  target: {
                    id: "communicationSkillsRating",
                    value: newValue,
                  },
                })
              }
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label={t("commentary")}
              helperText={t("feedbackEdit.helptextCommunication")}
              value={feedback.communicationSkillsComments}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    id: "communicationSkillsComments",
                    value: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t("feedbackEdit.titleAwareness")}
            </Typography>
            <Rating
              value={Number(feedback.selfAwarenessRating)}
              max={3}
              defaultValue={1}
              onChange={(_, newValue) =>
                handleInputChange({
                  target: {
                    id: "selfAwarenessRating",
                    value: newValue,
                  },
                })
              }
            />
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label={t("commentary")}
              helperText={t("feedbackEdit.helptextAwareness")}
              value={feedback.selfAwarenessComments}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    id: "selfAwarenessComments",
                    value: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t("feedbackEdit.titleRecommendations")}
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label={t("commentary")}
              helperText={t("feedbackEdit.helptextRecommendations")}
              value={feedback.recommendations}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    id: "recommendations",
                    value: e.target.value,
                  },
                })
              }
            />
          </Grid>
        </Grid>
        <Box mt={2} className="buttonContainer">
          <Button variant="formSubmit" color="primary" type="submit">
            {t("submit")}
          </Button>
          <Button
            variant="text"
            color="primary"
            type="button"
            onClick={handleSave}
          >
            {t("save")}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default EditFeedbackForm;
