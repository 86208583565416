import { createTheme } from "@mui/material/styles";

const loginTheme = createTheme({
  palette: {
    primary: {
      main: "#041e41",
    },
    secondary: {
      main: "#b29267",
    },
    success: {
      main: "#66bb6a",
    },
    warning: {
      main: "#ffa726",
    },
    error: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: "Open sans", // Change the default font family
    title: {
      fontFamily: "Open sans",
      fontSize: "48px",
      color: "#b29267",
      paddingTop: "10px",
      paddingBottom: "10px",
      "@media (max-width:600px)": {
        fontSize: "20px",
        padding: "0",
      },
    },
    subtitle: {
      fontFamily: "Open sans",
      fontSize: "36px",
      color: "black",
      marginBottom: "15px",
    },
    subtitle2: {
      fontFamily: "Open sans",
      fontSize: "30px",
      color: "black",
      marginBottom: "15px",
    },
    success: {
      fontFamily: "Open sans",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#66bb6a",
    },
    warning: {
      fontFamily: "Open sans",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#ffa726",
    },
    error: {
      fontFamily: "Open sans",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#f44336",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "login" },
          style: {
            textTransform: "none",
            textAlign: "center",
            border: "2px solid white",
            color: "#b29267",
            fontSize: "24px",
            margin: "10px",
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.2)",
            ":hover": {
              backgroundColor: "#133765",
              transform: "scale(1.1)",
              color: "white",
            },
            "@media (max-width:600px)": {
              fontSize: "16px",
              padding: "5px",
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            width: "fit-content",
            textTransform: "none",
            textAlign: "center",
            border: "2px solid white",
            color: "#b29267",
            fontSize: "24px",
            ":hover": {
              backgroundColor: "#133765",
              transform: "scale(1.1)",
              color: "white",
            },
          },
        },
        {
          props: { variant: "formSubmit" },
          style: {
            width: "fit-content",
            textTransform: "none",
            color: "#b29267",
            backgroundColor: "#041e41",
            fontSize: "24px",
            marginTop: "10px",
            ":hover": {
              backgroundColor: "#133765",
              transform: "scale(1.1)",
              color: "white",
            },
          },
        },
      ],
    },
  },
});

export default loginTheme;
