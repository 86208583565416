import React from 'react';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const logErrorToServer = async (error) => {
    try {
      // Send the error to your server
      await fetch("https://internship.ebs.ee/form/logError", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ error: error.message }),
      });

      // Reset the error boundary if needed
      resetErrorBoundary();
    } catch (logError) {
      console.error("Error logging to server:", logError);
    }
  };

  return (
    <div>
      <h2>Something went wrong</h2>
      <p>{error.message}</p>
      <button onClick={() => logErrorToServer(error)}>Try again</button>
    </div>
  );
};

export default ErrorFallback
