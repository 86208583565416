import React from "react";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";

import theme from "../Styles/theme";
import "../Styles/headerfooter.css";
import { useAuth } from "../Context/authContext";

export default function Header() {
  const { user } = useAuth();

  const handleLogoutClick = () => {
    window.location.href = "https://internship.ebs.ee/auth/azuread/logout";
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="header">
        <a href="/">
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={require("../Media/ebslogo.png")}
              alt="EBS logo"
              className="logo"
            />
            <Typography variant="title">EBS Internship Portal</Typography>
          </Box>
        </a>

        <Button
          variant="login"
          sx={{ display: user ? "flex" : "none" }}
          onClick={handleLogoutClick}
        >
          Logout
        </Button>
      </Box>
    </ThemeProvider>
  );
}
