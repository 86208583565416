import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  ThemeProvider,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Container,
} from "@mui/material";
import Header from "../Components/header";
import Footer from "../Components/footer";
import DisplayInternshipForm from "../Components/displayInternshipForm";
import { useTranslation } from "react-i18next";
import theme from "../Styles/theme";
import "../Styles/form.css";
import "../Styles/general.css";
import i18n from "../Language/langConfig";

const Form = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [resultMessage, setResultMessage] = useState("");
  const [form, setForm] = useState({
    studentName: "",
    studentEmail: "",
    organization: "",
    mentor: "",
    startDate: null,
    endDate: null,
    overview: "",
    agreeConfidentiality: false,
    dateSubmitted: null,
  });
  const [language, setLanguage] = useState("en");
  const [formFound, setFormFound] = useState("");
  const [formStatus, setFormStatus] = useState("In progress");
  const [showComments, setShowComments] = useState(false);
  const [showInfoInput, setShowInfoInput] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [decision, setDecision] = useState({
    status: "",
    token: token,
    comments: null,
    mentorName: null,
    mentorPosition: null,
  });

  const setResultMessageWithTimeout = (message, timeout = 5000) => {
    setResultMessage(message);

    setTimeout(() => {
      setResultMessage("");
    }, timeout);
  };

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    console.log(id);
    setDecision((prevDecision) => ({
      ...prevDecision,
      [id]: fieldValue,
    }));
    console.log(decision);
  };

  const renderFormComponent = () => {
    if (formFound === true) {
      return (
        <div>
          <Typography variant="body1" gutterBottom>
            {t("mentorForm.mentorInfo")}
          </Typography>
          <DisplayInternshipForm
            form={form}
            formStatus={formStatus}
            infoLevel={"mentor"}
          />
          {!formSubmitted ? (
            <Box className="radioContainer">
              <FormControl sx={{ width: "fit-content" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  {t("mentorForm.mentorChoice")}
                </FormLabel>
                <RadioGroup
                  id="status"
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={handleDecisionChange}
                >
                  <FormControlLabel
                    value="Accepted"
                    control={<Radio color="success" />}
                    label={t("mentorForm.choiceAccept")}
                  />
                  <FormControlLabel
                    value="Changes needed"
                    control={<Radio color="warning" />}
                    label={t("mentorForm.choiceChange")}
                  />
                  <FormControlLabel
                    value="Rejected"
                    control={<Radio color="error" />}
                    label={t("mentorForm.choiceReject")}
                  />
                </RadioGroup>
              </FormControl>

              {showInfoInput && (
                <Box sx={{ width: "fit-content" }}>
                  <FormLabel id="demo-radio-buttons-group-label">
                    {t("mentorForm.mentorData")}
                  </FormLabel>
                  <TextField
                    id="mentorName"
                    required
                    label={t("mentorForm.labelName")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={decision.mentorName}
                    onChange={handleInputChange}
                  />
                  <TextField
                    id="mentorPosition"
                    required
                    label={t("mentorForm.labelPosition")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={decision.mentorPosition}
                    onChange={handleInputChange}
                  />
                </Box>
              )}

              {showComments && (
                <TextField
                  id="comments"
                  label={t("comments")}
                  required
                  multiline
                  rows={4}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={decision.comments}
                  onChange={handleCommentsChange}
                />
              )}

              <Button variant="formSubmit" onClick={handleFormSubmit}>
                {t("submit")}
              </Button>
            </Box>
          ) : (
            <Typography variant="subtitle" gutterBottom>
              {t("mentorForm.thanks")}
            </Typography>
          )}
        </div>
      );
    } else if (formSubmitted) {
      return (
        <Typography variant="subtitle" gutterBottom>
          {t("mentorForm.thanks")}
        </Typography>
      );
    } else {
      return <Typography>{t("mentorForm.notFound")}</Typography>;
    }
  };

  const handleDecisionChange = (event) => {
    setDecision((prevDecision) => ({
      ...prevDecision,
      status: event.target.value,
    }));
    setShowComments(event.target.value === "Changes needed");
    setShowInfoInput(event.target.value === "Accepted");
  };

  const handleCommentsChange = (event) => {
    setDecision((prevDecision) => ({
      ...prevDecision,
      comments: event.target.value,
    }));
  };

  const handleFormSubmit = async () => {
    try {
      const response = await fetch(
        "https://internship.ebs.ee/form/mentor/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(decision),
        }
      );

      if (response.ok) {
        console.log("Form status updated successfully");
        setResultMessageWithTimeout("Form status updated successfully");
        setFormSubmitted(true);
      } else {
        console.error("Failed to update form status");
        setResultMessageWithTimeout("Failed to update form status");
      }
    } catch (error) {
      console.error("Error updating form status:", error);
      setResultMessageWithTimeout("Error updating form status");
    }
  };

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const response = await fetch(
          `https://internship.ebs.ee/form/mentor/fetch?token=${encodeURIComponent(
            token
          )}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const { application } = data;
          if (application) {
            setFormFound(true);
            setForm({
              studentName: application.studentName,
              studentEmail: application.studentEmail,
              organization: application.organization,
              mentor: application.mentor,
              startDate: application.startDate,
              endDate: application.endDate,
              overview: application.overview,
              agreeConfidentiality: application.agreeConfidentiality,
              dateSubmitted: application.dateSubmitted,
            });

            setLanguage(application.language || "en");

            setFormStatus(application.status || "In progress");
            if (application.decisionDate !== null) {
              setFormSubmitted(true);
            }
          }
        } else {
          console.error("Failed to fetch existing form");
          setFormFound(false);
        }
      } catch (error) {
        console.error("Error fetching existing form:", error);
        setFormFound(false);
      }
    };

    fetchForm();
  }, [token, formSubmitted]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <ThemeProvider theme={theme}>
      <Header></Header>
      <Box className="mainContainer">
        <Typography variant="subtitle">
          {t("mentorForm.mentorTitle")}
        </Typography>
        <div>{renderFormComponent()}</div>
        <Typography
          variant="body1"
          color={resultMessage.includes("failed") ? "error" : "success"}
        >
          {resultMessage}
        </Typography>
      </Box>
      <Footer></Footer>
    </ThemeProvider>
  );
};

export default Form;
