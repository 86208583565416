import React from "react"
import {
    Box,
    ThemeProvider,
    Typography,
    Link
} from "@mui/material"

import theme from "../Styles/theme"
import "../Styles/headerfooter.css"

export default function Footer() {
    return (
        <ThemeProvider theme={theme}>
            <Box className="footer">
                <Typography>
                    In case of questions, please contact following emails:
                </Typography>
                <Typography>
                    EBS Internship Coordinator: <Link sx={{ color: "white" }} href="mailto:made.katsevic@ebs.ee">{"made.katsevic@ebs.ee"}</Link>
                </Typography>
                <Typography>
                    IT Support: <Link sx={{ color: "white" }} href="mailto:it@ebs.ee">{"it@ebs.ee"}</Link>
                </Typography>
            </Box>
        </ThemeProvider>
    )
}