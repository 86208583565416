import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context/authContext";
import {
  Box,
  ThemeProvider,
  Select,
  MenuItem,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../Components/header";
import Footer from "../Components/footer";
import DisplayInternshipForm from "../Components/displayInternshipForm";
import theme from "../Styles/theme";
import "../Styles/form.css";
import "../Styles/general.css";
import { Link as RouterLink } from "react-router-dom";

const FormCoordinator = () => {
  const { user } = useAuth();
  const [resultMessage, setResultMessage] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [form, setForm] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [newFormStatus, setNewFormStatus] = useState("");
  const [comments, setComments] = useState("");
  const { pm } = useParams();
  const [token, setToken] = useState("");
  const [feedback, setFeedback] = useState(false);
  const [link, setLink] = useState("#");
  const [isActive, setIsActive] = useState(true);

  const setResultMessageWithTimeout = (message, timeout = 5000) => {
    setResultMessage(message);

    setTimeout(() => {
      setResultMessage("");
    }, timeout);
  };

  const handleStatusInputChange = (e) => {
    setNewFormStatus(e.target.value);
    setShowComments(e.target.value === "Changes needed");
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  useEffect(() => {
    const fetchExistingForm = async () => {
      if (user) {
        try {
          const response = await fetch(
            `https://internship.ebs.ee/form/coordinator/fetch?pm=${encodeURIComponent(
              pm
            )}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
            }
          );

          if (response.ok) {
            const data = await response.json();
            const { application } = data;
            if (application) {
              setForm(application);
              setFormStatus(application.status || "In progress");
              setToken(application.activeToken);
              setIsActive(application.active);
            }
          } else {
            console.error("Failed to fetch existing form");
            // Handle the error as needed
          }
        } catch (error) {
          console.error("Error fetching existing form:", error);
          // Handle the error as needed
        }
      }
    };

    fetchExistingForm();
  }, [isActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://internship.ebs.ee/form/feedback/fetch/${token}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.feedback.submitDate) {
            setFeedback(true);
            setLink(`/form/feedback/${token}`);
          }
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [token]);

  if (!user) {
    window.location.href = "https://internship.ebs.ee/";
    return null;
  }

  const handleStatusSubmit = async (event) => {
    try {
      const response = await fetch(
        "https://internship.ebs.ee/form/coordinator/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            applicationId: form.applicationId,
            newFormStatus,
            comments,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        console.log("Form status updated successfully");
        setResultMessageWithTimeout("Form status updated successfully");
        setFormStatus(newFormStatus);
      } else {
        console.error("Failed to update form status");
        setResultMessageWithTimeout("Failed to update form status");
      }
    } catch (error) {
      console.error("Error updating form status:", error);
      setResultMessageWithTimeout("Error updating form status");
    }
  };

  // Function to toggle application's active state
  const handleToggleActive = async () => {
    try {
      const response = await fetch(
        "https://internship.ebs.ee/form/coordinator/toggleApplicationStatus",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            applicationId: form.applicationId,
            active: !isActive, // Toggle the active state
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        console.log("Application status toggled successfully");
        setIsActive(!isActive); // Toggle isActive state locally
      } else {
        console.error("Failed to toggle application status");
        setResultMessageWithTimeout("Failed to toggle application status");
      }
    } catch (error) {
      console.error("Error toggling application status:", error);
      setResultMessageWithTimeout("Error toggling application status");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Header></Header>
      <Box className="mainContainer">
        <DisplayInternshipForm
          form={form}
          formStatus={formStatus}
          infoLevel={"coordinator"}
        />
        {feedback && (
          <RouterLink className="link" to={link}>
            Link to the feedback
          </RouterLink>
        )}
        <Box
          sx={{
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Select
            id="status"
            label="Status"
            value={newFormStatus}
            onChange={handleStatusInputChange}
          >
            <MenuItem value="Confirmed">Confirmed</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
            <MenuItem value="Changes needed">Changes needed</MenuItem>
          </Select>

          {showComments && (
            <TextField
              id="comments"
              label="Comments"
              required
              multiline
              rows={4}
              fullWidth
              margin="normal"
              variant="outlined"
              value={comments}
              onChange={handleCommentsChange}
            />
          )}

          <Button variant="formSubmit" onClick={handleStatusSubmit}>
            Update Status
          </Button>
          <Button
            variant="formSubmit"
            onClick={handleToggleActive}
            sx={{ marginTop: "1rem" }}
          >
            {isActive ? "Deactivate Application" : "Activate Application"}
          </Button>
          <Typography
            variant="body1"
            color={resultMessage.includes("failed") ? "error" : "success"}
          >
            {resultMessage === "" ? <br></br> : resultMessage}
          </Typography>
        </Box>
      </Box>
      <Footer></Footer>
    </ThemeProvider>
  );
};

export default FormCoordinator;
