// AuthContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import LoadingScreen from "../Components/loadingScreen";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      fetchUserData();
    }
  }, []);

  const fetchUserData = () => {
    fetch("https://internship.ebs.ee/user/getUserData", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log("Context:", res);
        if (res.ok) {
          return res.json();
        } else {
          // Redirect to login page if not authenticated
          window.location.href = "https://internship.ebs.ee";
        }
      })
      .then((userData) => {
        setUser(userData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const login = (userData) => {
    setUser(userData);
  };

  return (
    <AuthContext.Provider value={{ user, loading, login }}>
      {loading ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
