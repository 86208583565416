// 404.js
import React from 'react';
import { Typography, Button, Box, ThemeProvider } from '@mui/material';
import { Link } from 'react-router-dom';
import theme from '../Styles/theme';
import Header from '../Components/header';
import Footer from '../Components/footer';

const NotFound = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Box className="mainContainer">
        <Box className="loginBox">
          <Typography variant="title">Page Not Found</Typography>
          <Typography variant="subtitle">Sorry, the page you are looking for does not exist.</Typography>
          <Button variant="login" component={Link} to="/">
            Go to Home
          </Button>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
};

export default NotFound;
