import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

export default function LoadingScreen() {
  return (
    <Box sx={{ display: "flex", gap: "1px", alignItems: "center" }}>
      <CircularProgress />
      <Typography>Loading...</Typography>
    </Box>
  );
}
