import React from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
require("../Styles/general.css");

const DisplayFeedbackForm = ({ feedback, language }) => {
  const { t } = useTranslation();

  const renderBlock = (title, comments, rating = null) => {
    return (
      <Box className="formContainer">
        <Typography variant="h5" color="primary" gutterBottom>
          {title}
        </Typography>
        {rating && (
          <Typography
            variant="subtitle1"
            sx={{ paddingLeft: "1vw" }}
            gutterBottom
          >
            <strong>{t("feedbackDisplay.rating")}: </strong>
            {rating}/3
          </Typography>
        )}

        <Typography
          variant="subtitle1"
          sx={{ paddingLeft: "1vw" }}
          gutterBottom
        >
          <strong>{t("comments")}: </strong>
          {comments}
        </Typography>
      </Box>
    );
  };
  return (
    <Box className="detailsContainer">
      <Box className="formContainer">
        <Typography variant="h4" color="primary">
          {t("feedbackDisplay.title")}
        </Typography>
        <Typography variant="h5" gutterBottom>
          {t("submitDate")}: {feedback.submitDate}
        </Typography>
      </Box>

      <>
        {renderBlock(
          t("feedbackEdit.titleAgreements"),
          feedback.agreementsAndDeadlinesComments,
          feedback.agreementsAndDeadlinesRating
        )}
        {renderBlock(
          t("feedbackEdit.titleInitiative"),
          feedback.initiativeComments,
          feedback.initiativeRating
        )}
        {renderBlock(
          t("feedbackEdit.titleAnalytical"),
          feedback.analyticalSkillsComments,
          feedback.analyticalSkillsRating
        )}
        {renderBlock(
          t("feedbackEdit.titleCommunication"),
          feedback.communicationSkillsComments,
          feedback.communicationSkillsRating
        )}
        {renderBlock(
          t("feedbackEdit.titleAwareness"),
          feedback.selfAwarenessComments,
          feedback.selfAwarenessRating
        )}
        {renderBlock(
          t("feedbackEdit.titleRecommendations"),
          feedback.recommendations
        )}
      </>
    </Box>
  );
};

export default DisplayFeedbackForm;
