import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../Components/errorFallback";
import { useAuth } from "../Context/authContext";
import { Box, ThemeProvider } from "@mui/material";
import Header from "../Components/header";
import Footer from "../Components/footer";
import DisplayInternshipForm from "../Components/displayInternshipForm";
import EditInternshipForm from "../Components/editInternshipForm";
import theme from "../Styles/theme";
import "../Styles/form.css";
import "../Styles/general.css";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../Language/langConfig";

const Form = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [resultMessage, setResultMessage] = useState("");
  const [form, setForm] = useState({
    studentName: "",
    studentEmail: "",
    organization: "",
    mentor: "",
    startDate: null,
    endDate: null,
    overview: "",
    comments: "",
    agreeConfidentiality: false,
    dateSubmitted: null,
  });
  const [formStatus, setFormStatus] = useState("In progress");
  const [language, setLanguage] = useState("en");
  const [token, setToken] = useState("");
  const [feedback, setFeedback] = useState(false);
  const [link, setLink] = useState("#");

  const setResultMessageWithTimeout = (message, timeout = 5000) => {
    setResultMessage(message);

    setTimeout(() => {
      setResultMessage("");
    }, timeout);
  };

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    console.log(id);
    setForm((prevForm) => ({
      ...prevForm,
      [id]: fieldValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(JSON.stringify(form));

    try {
      const response = await fetch("https://internship.ebs.ee/form/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ form: form, language: language }),
        credentials: "include",
      });

      if (response.ok) {
        console.log("Form submitted successfully");
        setResultMessageWithTimeout(t("submitSuccess"));
        setFormStatus("Submitted");
      } else {
        console.error("Form submission failed");
        setResultMessageWithTimeout(t("submitFail"));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setResultMessageWithTimeout(t("submitFail"));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://internship.ebs.ee/form/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ form: form, language: language }),
        credentials: "include",
      });

      if (response.ok) {
        console.log("Form saved successfully");
        setResultMessageWithTimeout(t("saveSuccess"));
      } else {
        console.error("Form submission failed");
        setResultMessageWithTimeout(t("saveFail") + " " + response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setResultMessageWithTimeout(t("saveFail") + " " + error.message);
    }
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  useEffect(() => {
    const fetchExistingForm = async () => {
      if (user) {
        console.log(user.userData.localId);
        try {
          const response = await fetch(
            `https://internship.ebs.ee/form/fetch?pm=${encodeURIComponent(
              user.userData.localId
            )}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
            }
          );

          if (response.ok) {
            const data = await response.json();
            const { application } = data;
            if (application) {
              setForm({
                studentName: application.studentName,
                studentEmail: application.studentEmail,
                organization: application.organization,
                mentor: application.mentor,
                startDate: application.startDate,
                endDate: application.endDate,
                overview: application.overview,
                comments: application.comments,
                agreeConfidentiality: application.agreeConfidentiality,
                dateSubmitted: application.dateSubmitted,
              });

              setLanguage(application.language || "en");

              setToken(application.activeToken);

              setFormStatus(application.status || "In progress");
            }
          } else {
            console.error("Failed to fetch existing form");
          }
        } catch (error) {
          console.error("Error fetching existing form:", error);
        }
      }
    };

    fetchExistingForm();
  }, [user && user.userData.name, formStatus]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://internship.ebs.ee/form/feedback/fetch/${token}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.feedback.submitDate) {
            setFeedback(true);
            setLink(`/form/feedback/${token}`);
          }
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [token, feedback]);

  const renderFormComponent = () => {
    if (["Submitted", "Accepted", "Confirmed"].includes(formStatus)) {
      return (
        <div>
          <DisplayInternshipForm form={form} formStatus={formStatus} />
          {feedback && (
            <RouterLink className="link" to={link}>
              {t("feedbackLink")}
            </RouterLink>
          )}
        </div>
      );
    } else {
      return (
        <EditInternshipForm
          user={user}
          form={form}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          handleLanguageChange={handleLanguageChange}
          language={language}
          resultMessage={resultMessage}
          formStatus={formStatus}
        />
      );
    }
  };

  if (!user) {
    window.location.href = "https://internship.ebs.ee/";
    return null;
  }

  form.studentName = user.userData.name;
  form.studentEmail = user.userData.userName;

  return (
    <ThemeProvider theme={theme}>
      <Header></Header>
      <Box className="mainContainer">
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <ErrorFallback
              error={error}
              resetErrorBoundary={resetErrorBoundary}
            />
          )}
          onReset={() => window.location.reload()}
        >
          <div className="formContainer">{renderFormComponent()}</div>
        </ErrorBoundary>
      </Box>
      <Footer></Footer>
    </ThemeProvider>
  );
};

export default Form;
