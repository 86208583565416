import React from "react";
import "../Styles/login.css";
import "../Styles/general.css";
import theme from "../Styles/theme";
import { Box, Button, Typography, ThemeProvider } from "@mui/material";
import Header from "../Components/header";
import Footer from "../Components/footer";
import { useAuth } from "../Context/authContext";

const Login = () => {
  const handleLoginClick = () => {
    // Redirect the user to the MSAL authentication endpoint
    window.location.href = "https://internship.ebs.ee/auth/azuread/login";
  };
  const { user } = useAuth();

  if (user) {
    if (user.userData.adm) {
      window.location.href = "https://internship.ebs.ee/dashboard";
    } else {
      window.location.href = "https://internship.ebs.ee/form/student";
    }

    return null;
  }
  return (
    <ThemeProvider theme={theme}>
      <Header></Header>
      <Box className="mainContainer">
        <Box className="loginBox">
          <Typography variant="title">Login with your EBS account</Typography>
          <Button variant="login" onClick={handleLoginClick}>
            Login
          </Button>
        </Box>
      </Box>
      <Footer></Footer>
    </ThemeProvider>
  );
};

export default Login;
