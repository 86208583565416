import React from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
require("../Styles/general.css");

const DisplayInternshipForm = ({ form, formStatus, infoLevel }) => {
  const { t } = useTranslation();
  return (
    <Box className="detailsContainer">
      <Typography variant="h5" color="primary">
        {t("displayApplication.detailsTitle")}
      </Typography>
      <Typography variant="subtitle1">
        {t("applicationStatus")}: {t(`${formStatus}`)}
      </Typography>
      {infoLevel === "coordinator" && (
        <Typography
          variant="subtitle1"
          color={form.active ? "success" : "error"}
          gutterBottom
        >
          {form.active ? "Active" : "Inactive"}
        </Typography>
      )}
      <Typography variant="body1" gutterBottom>
        <strong>{t("submitDate")}:</strong>{" "}
        {form.dateSubmitted !== "" ? form.dateSubmitted : "None"}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>{t("displayApplication.studentName")}:</strong>{" "}
        {form.studentName}
      </Typography>
      {(infoLevel === "mentor" || infoLevel === "coordinator") && (
        <Typography variant="body1" gutterBottom>
          <strong>{t("displayApplication.studentEmail")}:</strong>{" "}
          {form.studentEmail}
        </Typography>
      )}
      <Typography variant="body1" gutterBottom>
        <strong>{t("displayApplication.organization")}:</strong>{" "}
        {form.organization}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>{t("displayApplication.mentor")}:</strong> {form.mentor}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>{t("labelStartDate")}:</strong> {form.startDate}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>{t("labelEndDate")}:</strong> {form.endDate}
      </Typography>
      <Typography variant="body1">
        <strong>{t("displayApplication.overview")}:</strong> {form.overview}
      </Typography>
      <br></br>
      {infoLevel === "coordinator" && (
        <div>
          <Typography variant="body1" gutterBottom>
            <strong>{t("comments")}:</strong>{" "}
            {form.comments ? form.comments : "None"}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>{t("displayApplication.mentorDecisionDate")}:</strong>{" "}
            {form.decisionDate ? form.decisionDate : "None"}
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default DisplayInternshipForm;
