import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ThemeProvider,
  Table,
  TableRow,
  TableCell,
  Link,
  TableHead,
  TableBody,
  Button,
  TextField,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Header from "../Components/header";
import Footer from "../Components/footer";
import { useAuth } from "../Context/authContext";
import "../Styles/general.css";
import theme from "../Styles/theme";

const Dashboard = () => {
  const { user } = useAuth();
  const [applications, setApplications] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [searchQuery, setSearchQuery] = useState("");

  const fetchApplications = async () => {
    try {
      const response = await fetch(
        `https://internship.ebs.ee/form/coordinator/getAll?status=${statusFilter}&active=${activeFilter}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${searchQuery}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setApplications(data.applications);
      } else {
        console.error("Failed to fetch applications");
      }
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, [statusFilter, activeFilter, sortBy, sortOrder, searchQuery]);

  const InternshipFormTableRow = ({ application }) => {
    return (
      <TableRow>
        <TableCell>{application.updatedAt}</TableCell>
        <TableCell>{application.studentName}</TableCell>
        <TableCell>{application.studentEmail}</TableCell>
        <TableCell>{application.organization}</TableCell>
        <TableCell>{application.status}</TableCell>
        <TableCell>
          <Link
            href={`/form/coordinator/${application.id}`}
            underline="hover"
          >
            Review
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  const InternshipFormTable = ({ applications }) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                  <strong>Last Activity</strong>
                </Typography>
                <Button
                  onClick={() => {
                    setSortBy("updatedAt");
                    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
                  }}
                >
                  {sortOrder === "ASC" ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )}
                </Button>
              </Box>
            </TableCell>

            <TableCell>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                  <strong>Student's Name</strong>
                </Typography>
              </Box>
            </TableCell>
            <TableCell>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                  <strong>Student's Email</strong>
                </Typography>
              </Box>
            </TableCell>
            <TableCell>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                  <strong>Organization</strong>
                </Typography>
                <Button
                  onClick={() => {
                    setSortBy("Organization");
                    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
                  }}
                >
                  {sortOrder === "ASC" ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )}
                </Button>
              </Box>
            </TableCell>
            <TableCell>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                  <strong>Status</strong>
                </Typography>
                <Button
                  onClick={() => {
                    setSortBy("Status");
                    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
                  }}
                >
                  {sortOrder === "ASC" ? (
                    <ArrowUpwardIcon />
                  ) : (
                    <ArrowDownwardIcon />
                  )}
                </Button>
              </Box>
            </TableCell>
            <TableCell>
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map((application) => (
            <InternshipFormTableRow
              key={application.id}
              application={application}
            />
          ))}
        </TableBody>
      </Table>
    );
  };

  if (!user || !user.userData.adm) {
    window.location.href = "https://internship.ebs.ee/";
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header />
        <Box className="mainContainer">
          <Typography variant="h4" gutterBottom>
            Dashboard
          </Typography>
          <Box className="filtersContainer">
            <TextField
              id="search"
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Box sx={{ marginTop: "5vh" }}>
              <FormControl className="filterTab">
                <InputLabel id="application-status-filter">Status</InputLabel>
                <Select
                  labelId="application-status-filter"
                  label="Status"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="Submitted">Submitted</MenuItem>
                  <MenuItem value="Accepted">Accepted</MenuItem>
                  <MenuItem value="In progress">In progress</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                  <MenuItem value="Changes needed">Changes needed</MenuItem>
                  <MenuItem value="Confirmed">Confirmed</MenuItem>
                  {/* Add more status options as needed */}
                </Select>
              </FormControl>

              <FormControl className="filterTab">
                <InputLabel id="application-active-filter">Active</InputLabel>
                <Select
                  labelId="application-active-filter"
                  label="Active"
                  value={activeFilter}
                  onChange={(e) => setActiveFilter(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="1">Active</MenuItem>
                  <MenuItem value="0">Inactive</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="filterTab">
                <InputLabel id="application-sort-filter">Sort By</InputLabel>
                <Select
                  labelId="application-sort-filter"
                  label="Sort By"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="startDate">Start Date</MenuItem>
                  <MenuItem value="endDate">End Date</MenuItem>
                  <MenuItem value="createdAt">Date Created</MenuItem>
                  <MenuItem value="Organization">Organization</MenuItem>
                  <MenuItem value="Status">Status</MenuItem>
                  <MenuItem value="updatedAt">Latest Activity</MenuItem>
                  <MenuItem value="dateSubmitted">Date Submitted</MenuItem>
                  <MenuItem value="decisionDate">Decision date</MenuItem>
                  {/* Add more sorting options as needed */}
                </Select>
              </FormControl>

              <FormControl className="filterTab">
                <InputLabel id="application-order-filter">
                  Sort Order
                </InputLabel>
                <Select
                  labelId="application-order-filter"
                  label="Sort Order"
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                >
                  <MenuItem value="ASC">Ascending</MenuItem>
                  <MenuItem value="DESC">Descending</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Button variant="text" onClick={fetchApplications}>
            Refresh list
          </Button>
          <InternshipFormTable applications={applications} />
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
