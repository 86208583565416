import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Box, ThemeProvider } from "@mui/material";
import Header from "../Components/header";
import Footer from "../Components/footer";
import "../Styles/general.css";
import theme from "../Styles/theme";
import moment from "moment";
import DisplayFeedbackForm from "../Components/displayFeedbackForm";
import EditFeedbackForm from "../Components/editFeedbackForm";
import { useTranslation } from "react-i18next";
import i18n from "../Language/langConfig";

const MentorFeedbackForm = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [language, setLanguage] = useState("en");
  const [resultMessage, setResultMessage] = useState("");
  const [feedback, setFeedback] = useState({
    agreementsAndDeadlinesRating: 1,
    agreementsAndDeadlinesComments: "",
    initiativeRating: 1,
    initiativeComments: "",
    analyticalSkillsRating: 1,
    analyticalSkillsComments: "",
    communicationSkillsRating: 1,
    communicationSkillsComments: "",
    selfAwarenessRating: 1,
    selfAwarenessComments: "",
    recommendations: "",
    submitDate: null,
  });
  const [submitDate, setSubmitDate] = useState(null);

  const setResultMessageWithTimeout = (message, timeout = 5000) => {
    setResultMessage(message);

    setTimeout(() => {
      setResultMessage("");
    }, timeout);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://internship.ebs.ee/form/feedback/fetch/${token}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFeedback(data.feedback);
          setLanguage(data.feedback.language);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [token, submitDate]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [id]: inputValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newSubmitDate = moment().format("YYYY-MM-DD HH:mm:ss");
    try {
      const response = await fetch(
        "https://internship.ebs.ee/form/feedback/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, feedback, submitDate: newSubmitDate }),
        }
      );

      if (response.ok) {
        console.log("Form saved successfully");
        setResultMessageWithTimeout(t("submitSuccess"));
        setSubmitDate(newSubmitDate);
      } else {
        console.error("Form save failed");
        setResultMessageWithTimeout(t("submitFail"));
      }
    } catch (error) {
      console.error("Error saving form:", error);
      setResultMessageWithTimeout(t("submitFail"));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://internship.ebs.ee/form/feedback/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, feedback }),
        }
      );

      if (response.ok) {
        console.log("Form saved successfully");
        setResultMessageWithTimeout(t("saveSuccess"));
      } else {
        console.error("Form submission failed");
        setResultMessageWithTimeout(t("saveFail"));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setResultMessageWithTimeout(t("saveFail"));
    }
  };

  const renderFormComponent = () => {
    if (feedback.submitDate) {
      return (
        <div>
          <DisplayFeedbackForm feedback={feedback} language={language}></DisplayFeedbackForm>
        </div>
      );
    } else {
      return (
        <EditFeedbackForm
          feedback={feedback}
          handleInputChange={handleInputChange}
          handleSave={handleSave}
          handleSubmit={handleSubmit}
          language={language}
        />
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Header></Header>
      <Box className="mainContainer">{renderFormComponent()}</Box>
      <Typography
        variant="body1"
        color={resultMessage.includes("failed") ? "error" : "success"}
      >
        {resultMessage === "" ? <br></br> : resultMessage}
      </Typography>
      <Footer></Footer>
    </ThemeProvider>
  );
};

export default MentorFeedbackForm;
