// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Views/login';
import FormStudent from './Views/formStudent';
import FormMentor from './Views/formMentor';
import FormCoordinator from './Views/formCoordinator';
import Dashboard from './Views/dashboard';
import MentorFeedbackForm from './Views/mentorFeedback';
import NotFound from './Views/404'; 

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/form/student" element={<FormStudent />} />
        <Route path="/form/mentor/:token" element={<FormMentor />} />
        <Route path="/form/feedback/:token" element={<MentorFeedbackForm />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/form/coordinator/:pm" element={<FormCoordinator />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
