import React from "react";
import {
  Box,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./languageSwitcher";
import "../Styles/general.css";

const languages = [
  { code: "en", name: "English" },
  { code: "et", name: "Estonian" },
];

const EditInternshipForm = ({
  user,
  form,
  handleInputChange,
  handleSubmit,
  handleSave,
  handleLanguageChange,
  language,
  resultMessage,
  formStatus,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <LanguageSwitcher
        languages={languages}
        selectedLanguage={language}
        onLanguageChange={handleLanguageChange}
      />
      <Typography variant="subtitle">
        {t("editApplication.applicationTitle")}
      </Typography>
      <Typography variant="subtitle2">
        {t("editApplication.fillerName")}: {user.userData.name}
      </Typography>
      <Typography variant="subtitle2">
        {t("applicationStatus")}: {t(`${formStatus}`)}
      </Typography>
      {form.comments !== "" && (
        <Typography variant="body1" gutterBottom>
          <strong>{t("comments")}:</strong> {form.comments}
        </Typography>
      )}
      <Typography>{t("editApplication.formInfoText")}</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          id="organization"
          required
          label={t("editApplication.labelOrganization")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={form.organization}
          onChange={handleInputChange}
          helperText={t("editApplication.helperTextOrganization")}
        />
        <TextField
          id="mentor"
          required
          type="email"
          label={t("editApplication.labelMentor")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={form.mentor}
          onChange={handleInputChange}
          helperText={t("editApplication.helperTextMentor")}
        />
        <Box className="dateFieldContainer">
          <Typography variant="body1" gutterBottom>
            {t("editApplication.startDate")}:
          </Typography>
          <input
            className="dateInput"
            type="date"
            id="startDate"
            required
            label={t("editApplication.startDate")}
            value={form.startDate}
            onChange={(e) => handleInputChange(e)}
            helperText={t("editApplication.helperTextStartDate")}
          ></input>
          <Typography variant="body1" gutterBottom>
            {t("editApplication.endDate")}:
          </Typography>
          <input
            className="dateInput"
            type="date"
            id="endDate"
            required
            label={t("editApplication.endDate")}
            value={form.endDate}
            onChange={(e) => handleInputChange(e)}
            helperText={t("editApplication.helperTextEndDate")}
          ></input>
        </Box>

        <TextField
          id="overview"
          required
          label={t("editApplication.labelOverview")}
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={10}
          helperText={t("editApplication.helperTextOverview")}
          value={form.overview}
          onChange={handleInputChange}
        />
        <Box>
          <Typography variant="subtitle">
            {t("editApplication.confidentiality")}
          </Typography>
          <Typography>{t("editApplication.confidentialityInfo1")} </Typography>
          <br></br>
          <Typography>{t("editApplication.confidentialityInfo2")} </Typography>
          <FormControlLabel
            required
            control={<Checkbox id="agreeConfidentiality" />}
            label={t("editApplication.confidentialityCheckLabel")}
            labelPlacement="right"
            checked={form.agreeConfidentiality}
            onChange={handleInputChange}
          />
          <br></br>
        </Box>
        <Box id="buttonContainer" className="buttonContainer">
          <Button variant="formSubmit" color="primary" type="submit">
            {t("submit")}
          </Button>
          <Button color="secondary" type="button" onClick={handleSave}>
            {t("save")}
          </Button>
        </Box>

        <Typography
          variant="body1"
          color={resultMessage.includes("failed") ? "error" : "success"}
        >
          {resultMessage === "" ? <br></br> : resultMessage}
        </Typography>
      </form>
    </div>
  );
};

export default EditInternshipForm;
